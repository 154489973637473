import { SxProps } from '@mui/system'
import { ThemeObjectInterFace } from '../../../util/types'

export const container: SxProps = {
  display: 'flex',
  marginTop: 1,
  gap: 1,
  justifyContent: 'flex-end',
  alignItems: 'center',
}

export const buttonStyle = (globalTheme: ThemeObjectInterFace): SxProps => ({
  paddingX: 3,
  paddingY: 1,
  border: `2px solid ${globalTheme?.main_color}`,
  borderRadius: 2,
  backgroundColor: 'white',
  boxShadow:
    'rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;',
  color: globalTheme?.main_color,
  fontSize: 16,
  lineHeight: 1,
  '&:hover': {
    backgroundColor: 'white',
  },
})
