import { SxProps } from '@mui/system'

export const rotateContainer: SxProps = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  padding: 2,
  paddingX: 4,
}

export const rotateWrapper: SxProps = {
  backgroundColor: 'white',
  border: '2px solid black',
  borderRadius: 6,
  padding: 4,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
}

export const textContent: SxProps = {
  fontWeight: 400,
  fontSize: 22,
  textAlign: 'center',
}
