import { SxProps } from '@mui/system'
import { ThemeObjectInterFace } from '../../../util/types'

export const imageContainer = (globalTheme: ThemeObjectInterFace): SxProps => ({
  minWidth: '65%',
  width: 'max-content',
  padding: '20px 25px',
  border: '2px solid',
  margin: '0 auto',
  borderRadius: '20px',
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: globalTheme.secondary_color,
})

export const imageStyle: SxProps = {
  width: { xs: '100%', md: 'auto' },
  height: { xs: '60vh', md: 'auto' },
  borderRadius: '10px',
}
