export const diaVideoApicall = (
  sliderValue: number,
  offSetSeconds: number,
  post_data: string,
) => {
  setTimeout(async () => {
    const url = new URL(window?.location?.href)

    const pathname = url.pathname
    const id = pathname.split('/')[1]
    /* istanbul ignore next */
    const api_protocol =
      process.env.REACT_APP_MX8_SURVEY_API_PROTOCOL ?? 'https'
    const api_url = `${api_protocol}://${process.env.REACT_APP_MX8_SURVEY_API_DOMAIN}/v1/store/${id}?value=${sliderValue}&offset_seconds=${offSetSeconds}`
    try {
      await fetch(api_url, {
        method: 'POST',
        body: post_data,
      })
    } catch (error) {}
  }, 1500)
}
