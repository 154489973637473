import { SxProps } from '@mui/system'
import { ThemeObjectInterFace } from '../../../util/types'

/* istanbul ignore next */
export const ratingContainer = (isLandScapeMode: boolean): SxProps => ({
  minHeight: isLandScapeMode ? '100%' : 'auto',
})

export const imageContainer: SxProps = {
  width: 'auto',
  padding: '2px 20px',
  border: '2px solid',
  borderRadius: 3,
  backgroundColor: 'white',
  flexBasis: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}

/* istanbul ignore next */
export const ratingActionContainer = (
  globalTheme: ThemeObjectInterFace,
  isLandScapeMode: boolean,
): SxProps => ({
  padding: isLandScapeMode ? '24px 27px 8px 18px' : '8px 22.4px 8px 12px',
  border: isLandScapeMode
    ? '2px solid'
    : `2px solid ${globalTheme?.main_color}`,
  borderRadius: isLandScapeMode ? '12px' : 10,
  margin: isLandScapeMode ? null : '8px 16px 0',
  boxShadow: 3,
  backgroundColor: 'white',
})

/* istanbul ignore next */
export const textMark = (
  globalTheme: ThemeObjectInterFace,
  isLandScapeMode: boolean,
): SxProps => ({
  width: '30%',
  color: globalTheme?.main_color,
  fontSize: isLandScapeMode ? '1.2rem' : null,
})

export const ratingWithImageContainer = {
  display: 'flex',
  justifyContent: 'space-between',
}

/* istanbul ignore next */
export const textContent = (globalTheme: ThemeObjectInterFace): SxProps => ({
  color: globalTheme?.main_color,
  textAlign: 'center',
  fontWeight: 900,
  marginBottom: 1,
})

export const buttonContainer: SxProps = {
  display: 'fex',
  width: '100%',
  justifyContent: 'space-between',
  paddingX: 2,
}

/* istanbul ignore next */
export const responseContainer = (
  isLandScapeMode: boolean,
  isImagePresent: boolean,
): SxProps => ({
  display: isLandScapeMode ? 'flex' : null,
  flexDirection: isLandScapeMode ? 'column' : null,
  justifyContent: isLandScapeMode ? 'center' : null,
  flexBasis: isImagePresent && isLandScapeMode ? '45%' : '100%',
})

export const actionBtnWrapper: SxProps = {
  position: 'absolute',
  bottom: 0,
  right: 0,
}

export const commonBtnWrapper: SxProps = {
  ...actionBtnWrapper,
  left: 0,
  right: null,
}
