import { Box } from '@mui/system'
import { FC } from 'react'
import Hints from '../../../Hints'
import { IconButton, Input } from '@mui/material'
import { Send } from '@mui/icons-material'
import { inputContainer, modalContainer, modalParent } from './style'
import { useThemeContext } from '../../../../../globalContext/global-context'
import { CommentFloatingModalProps } from '../../../../../util/types'

const CommentFormModal: FC<CommentFloatingModalProps> = ({
  message,
  handleSend,
  commentText,
  isCommentModalOpen,
  isLandScapeMode,
  setCommentText,
  modalRef,
  commentInputRef,
}) => {
  const globalTheme = useThemeContext().globalObject.themeData.theme
  return (
    <Box
      sx={modalParent(isCommentModalOpen, isLandScapeMode)}
      className="modal-parent"
    >
      <Box sx={modalContainer(globalTheme.secondary_color)} ref={modalRef}>
        <Hints
          text={message ?? ''}
          sx={{ color: globalTheme.secondary_color, fontSize: '16px' }}
        />
        <form onSubmit={handleSend} style={inputContainer}>
          <Input
            data-testid="modal-comment-input"
            placeholder="Comment"
            value={commentText}
            onChange={(e) => setCommentText(e.target.value)}
            fullWidth
            sx={{ color: globalTheme.secondary_color }}
            ref={commentInputRef}
            autoFocus
          />
          <IconButton
            data-testid="comment-send-btn"
            type="submit"
            sx={{ color: globalTheme.secondary_color }}
          >
            <Send />
          </IconButton>
        </form>
      </Box>
    </Box>
  )
}

export default CommentFormModal
