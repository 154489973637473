import { SxProps } from '@mui/system'

export const containerStyle = (isLandScapeMode: boolean): SxProps => ({
  marginTop: isLandScapeMode ? null : '8px',
  display: 'flex',
  flexDirection: 'row-reverse',
  justifyContent: isLandScapeMode ? 'center' : 'flex-start',
  '& > *': {
    flex: '0 0 auto',
    maxWidth: '100%',
  },
  '& > * + *': {
    mt: 1,
  },
})

export const wrapper: SxProps = {
  display: 'flex',
  flex: 1,
  p: 1,
  justifyContent: 'space-between',
}

export const buttonWrapper = (isLandScapeMode: boolean): SxProps => ({
  display: 'flex',
  justifyContent: isLandScapeMode ? 'center' : 'flex-end',
  flexWrap: 'wrap',
  flex: 1,
})
