import { FC, useMemo } from 'react'
import { Box } from '@mui/system'
import { CustomActionRequest, TextActionRequest } from 'chat-ui-react'
import {
  MultiSelectInput,
  RankSorter,
  Rating,
  SelectInput,
  TextInput,
  ImageQuestion,
  MaxDiff,
  GridLandScapeComp,
} from '../../InputComponents'
import {
  CompleteInputResponse,
  CustomComponentType,
  MultiSelectTypes,
  RankSorterTypes,
  RatingResponse,
  SelectTypes,
  ChatQuestionProps,
  ImageQuestionType,
  TimerImageTypes,
  MaxDiffActionRequestTypes,
  GridSelectAction,
} from '../../../util/types'
import { CircularProgress } from '@mui/material'
import { Modal } from '../../ui'

const ChatQuestion: FC<ChatQuestionProps> = (props) => {
  const {
    actReq,
    chatCtl,
    setRankMostLeast,
    setSliderValue,
    setUpdateMarkValue,
    sliderValue,
    updateMarkValue,
    isLandScapeMode,
    handleImageClick,
    surveyQuestion,
    loading,
    isCommentModalOpen,
  } = props
  const CustomComponent = useMemo((): CustomComponentType => {
    if (!actReq || actReq.type !== 'custom') {
      return null as unknown as CustomComponentType
    }
    return (actReq as CustomActionRequest)
      .Component as unknown as CustomComponentType
  }, [actReq])

  const inputProps = {
    isLandScapeMode,
    chatController: chatCtl,
    handleImageClick,
    surveyQuestion,
    isCommentModalOpen,
  }

  if (loading && isLandScapeMode) {
    return (
      <CircularProgress
        data-testid="response-circular-loader"
        style={{ height: '60px', width: '60px' }}
      />
    )
  }

  const renderQuestionComponent = () => {
    switch (actReq.type) {
      case 'text':
      case 'numeric':
        return (
          <TextInput
            {...inputProps}
            actionRequest={actReq as TextActionRequest}
          />
        )
      case 'select':
        return (
          <SelectInput {...inputProps} actionRequest={actReq as SelectTypes} />
        )
      case 'multi-select':
        return (
          <MultiSelectInput
            {...inputProps}
            actionRequest={actReq as MultiSelectTypes}
          />
        )
      case 'rank_sort':
        return (
          <RankSorter
            {...inputProps}
            actionRequest={actReq as RankSorterTypes}
            setRankMostLeast={setRankMostLeast}
          />
        )
      case 'rating':
        return (
          <Rating
            {...inputProps}
            key={'rating-1'}
            actionRequest={actReq as RatingResponse}
            setSliderValue={setSliderValue}
            setUpdateMarkValue={setUpdateMarkValue}
            sliderValue={sliderValue}
            updateMarkValue={updateMarkValue}
          />
        )
      case 'image':
        return (
          <ImageQuestion
            {...inputProps}
            surveyQuestion={surveyQuestion as ImageQuestionType}
            actionRequest={actReq as TimerImageTypes}
          />
        )
      case 'grid_select':
      case 'grid_multi_select':
      case 'grid_rating':
      case 'grid_numeric':
        return (
          <GridLandScapeComp
            {...inputProps}
            actionRequest={actReq as GridSelectAction}
            setUpdateMarkValue={setUpdateMarkValue}
            updateMarkValue={updateMarkValue}
          />
        )
      case 'max_diff':
        return (
          <MaxDiff
            {...inputProps}
            actionRequest={actReq as MaxDiffActionRequestTypes}
          />
        )
      case 'custom':
        return (
          <CustomComponent
            chatController={chatCtl}
            actionRequest={actReq as CustomActionRequest}
          />
        )
      case 'complete':
        return (
          <Modal
            isLandScapeMode={isLandScapeMode}
            actionRequest={actReq as CompleteInputResponse}
            type="CompleteInput"
          />
        )
    }
  }

  return (
    <Box data-testid="chat-question" width={'100%'}>
      {/* istanbul ignore next */}
      {renderQuestionComponent()}
    </Box>
  )
}

export default ChatQuestion
