import { SxProps } from '@mui/system'
import { ThemeObjectInterFace } from '../../util/types'

export const parentContainer = (isLandScapeMode: boolean): SxProps => ({
  maxWidth: isLandScapeMode ? '100%' : 700,
  minHeight: '100%',
  height: isLandScapeMode ? null : '100vh',
  width: '100%',
  padding: {
    sm: isLandScapeMode ? '0' : '20px',
  },
  margin: '0 auto',
  display: 'flex',
})

export const chatBodyContainer = (
  globalTheme: ThemeObjectInterFace,
  isLandScapeMode: boolean,
): SxProps => ({
  position: 'relative',
  width: '100%',
  padding: {
    md: isLandScapeMode ? '20px 50px' : null,
    sm: isLandScapeMode ? '20px 15px' : null,
  },
  borderWidth: 0.5,
  borderRadius: '20px 20px 20px 20px',
  WebkitBoxShadow: '-1px 4px 16px -8px rgba(0,0,0,0.83)',
  MozBoxShadow: '-1px 4px 16px -8px rgba(0,0,0,0.83)',
  boxShadow: '-1px 4px 16px -8px rgba(0,0,0,0.83)',
  backgroundImage: `url(${globalTheme?.background_image})`,
})

export const chatBodyWrapper = (
  isLandScapeMode: boolean,
  isSelectType: boolean,
  isTimerImageOpen: boolean,
): SxProps => ({
  minHeight: isLandScapeMode ? '100%' : null,
  width: '100%',
  height: isLandScapeMode ? null : '100%',
  display: 'flex',
  justifyContent: 'space-around',
  flexDirection: isLandScapeMode ? 'row' : 'column',
  alignItems: {
    md: isSelectType ? 'start' : 'center',
    xs: 'center',
  },
  position: 'relative',
  overflow: isLandScapeMode ? null : 'auto',
  ...(isTimerImageOpen
    ? {
        backgroundColor: 'gray',
        borderRadius: '15px',
        opacity: '0.9',
        zIndex: 1,
      }
    : {}),
})

export const videoBoxContainer: SxProps = {
  flexBasis: '100%',
}
