import { SxProps } from '@mui/system'

export const draggableContainer: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  position: 'fixed',
  backgroundColor: '#000',
  borderRadius: '12px',
  zIndex: 90,
  cursor: 'grab',
}
export const btnContainer: SxProps = {
  padding: '6px 8px',
}
export const btnStyle = (color: string): SxProps => ({
  width: '100%',
  padding: 0,
  display: 'flex',
  justifyContent: 'flex-start',
  gap: 1,
  color,
})
