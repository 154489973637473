import { SxProps } from '@mui/system'
import { ThemeObjectInterFace } from '../../../util/types'

export const parent: SxProps = {
  display: {
    md: 'block',
    xs: 'flex',
  },
  gap: {
    md: null,
    xs: '15px',
  },
  justifyContent: 'space-between',
}

export const container = (
  isLandScapeMode: boolean,
  isFocused: boolean,
): SxProps => ({
  display: 'flex',
  justifyContent: 'space-between',
  flexBasis: isFocused ? '82%' : '',
  gap: {
    md: '20px',
    xs: isFocused ? '15px' : '20px',
  },
  alignItems: isLandScapeMode ? 'center' : 'flex-start',
  '& > *': {
    flexBasis: isLandScapeMode ? '50% ' : '100%',
  },
})

export const inputContainer = (
  isLandScapeMode: boolean,
  isFocused: boolean,
): SxProps => ({
  paddingBottom: {
    md: isLandScapeMode ? '2rem' : null,
    xs: isLandScapeMode && !isFocused ? '2rem' : null,
  },
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: isLandScapeMode ? 'center' : 'flex-start',
})

export const inputWrapper = (
  isLandScapeMode: boolean,
  globalTheme: ThemeObjectInterFace,
  isFocused: boolean,
): SxProps => ({
  width: '100%',
  height: isLandScapeMode ? 'max-content' : null,
  backgroundColor: isLandScapeMode ? 'white' : globalTheme?.main_color,
  padding: {
    md: isLandScapeMode ? '30px 20px' : '13px 0px 10px 13px',
    xs: !isFocused && isLandScapeMode ? '30px 20px' : '15px 9px 15px 15px',
  },
  borderRadius: isLandScapeMode ? '15px' : '0px 0px 20px 20px',
  borderColor: globalTheme?.main_color,
  borderStyle: 'solid',
  display: 'flex',
  '& > *': {
    flex: isLandScapeMode ? null : '1 1 auto',
    minWidth: 0,
  },
  '& > * + *': {
    ml: 2,
  },
  '& :last-child': {
    flex: '0 1 auto',
  },
  zIndex: 3,
  boxShadow: isLandScapeMode
    ? 'rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;'
    : '',
  ...(isFocused && isLandScapeMode
    ? {
        outline: `3px solid ${globalTheme?.main_color}`,
        outlineOffset: '3px',
      }
    : {}),
})

export const hintWrapper = (isFocused: boolean): SxProps => ({
  display: {
    md: 'block',
    xs: isFocused ? 'none' : 'block',
  },
})

export const inputStyle = (
  globalTheme: ThemeObjectInterFace,
  isFocused: boolean,
  isLandScapeMode: boolean,
): SxProps => ({
  height: '100%',
  width: '100%',
  borderRadius: '15px',
  border: 'none',
  backgroundColor: globalTheme?.secondary_color,
  '& textarea': {
    color: isLandScapeMode ? globalTheme?.main_color : null,
    fontSize: {
      md: '16px',
      xs: isFocused ? '14px' : '16px',
    },
  },
  '& .MuiOutlinedInput-root': {
    padding: '5px 5px 5px 10px',
    '& fieldset': {
      borderColor: 'transparent', // Remove border color when not focused
    },
    '&:hover fieldset': {
      borderColor: 'transparent', // Remove border color on hover
    },
    '&.Mui-focused fieldset': {
      borderColor: 'transparent', // Remove border color when focused
    },
  },
})

export const textActionBtnWrapper: SxProps = {
  width: { xs: 'auto', md: '50%' },
  position: { xs: 'static', lg: 'absolute' },
  bottom: {
    sm: null,
    md: '15px',
  },
  right: {
    sm: null,
    md: '15px',
  },
}
