import { FC } from 'react'
import { ActionButtonProps } from '../../../util/types'
import { Button, Tooltip } from '@mui/material'
import { actionBtn } from './styles'
import { useThemeContext } from '../../../globalContext/global-context'
import CommonImageComp from '../CommonImageComp'

const ActionButton: FC<ActionButtonProps> = ({
  onClick,
  testId,
  currentVal,
  disabled,
  isSelected,
  isLandScapeMode,
}) => {
  const { globalObject } = useThemeContext()
  const globalTheme = globalObject.themeData.theme

  return (
    <Tooltip title={currentVal?.image && currentVal?.text && currentVal?.text}>
      <Button
        data-testid={testId}
        disabled={disabled}
        onClick={onClick}
        sx={actionBtn(globalTheme, !!isSelected, currentVal)}
      >
        <CommonImageComp
          currentVal={currentVal}
          isLandScapeMode={isLandScapeMode}
        />
      </Button>
    </Tooltip>
  )
}

export default ActionButton
