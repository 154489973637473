import { keyframes, SxProps } from '@mui/system'

const typingAnimation = keyframes({
  '0%': {
    opacity: 0.3,
  },
  '50%': {
    opacity: 1,
  },
  '100%': {
    opacity: 0.3,
  },
})

export const boxStyleCommon: SxProps = {
  position: 'relative',
  '&::after': {
    content: '""',
    width: 10,
    height: 0,
    position: 'absolute',
    borderStyle: 'solid',
    borderWidth: '8px',
    borderColor: `#fff #fff transparent transparent`,
    left: 4,
    top: 16,
    filter: 'drop-shadow(-2px 1px 1px rgba(0,0,0,.3))',
  },
}

export const typingContainer: SxProps = {
  display: 'flex',
  alignItems: 'center',
  backgroundColor: 'white',
  padding: '14px',
  borderRadius: '0px 15px 15px 15px',
  marginLeft: '20px',
  width: 'fit-content',
  boxShadow: '4px 4px 1px rgba(0, 0, 0, 0.3)',
}

export const dotStyle = (delay: string): SxProps => ({
  width: '8px',
  height: '8px',
  borderRadius: '50%',
  backgroundColor: '#b3b3b3',
  margin: '0 3px',
  opacity: 0,
  animation: `${typingAnimation} 1s infinite`,
  animationDelay: delay,
})
