import { Typography } from '@mui/material'
import { FC, ReactElement } from 'react'
import { useThemeContext } from '../../../globalContext/global-context'
import Markdown from 'markdown-to-jsx'
import { typoStyle } from './style'
import { HintsProps } from '../../../util/types'

const Hints: FC<HintsProps> = ({ text, title, sx }): ReactElement => {
  const { globalObject } = useThemeContext()
  const globalTheme = globalObject.themeData.theme
  const style = {
    ...typoStyle(globalTheme.main_color),
    ...sx,
  }
  return (
    <Typography title={title} sx={style}>
      <Markdown
        options={{
          overrides: {
            img: {
              props: {
                className: 'custom-image', // Apply custom Material-UI styles
              },
            },
          },
          disableParsingRawHTML: true,
        }}
      >
        {/* istanbul ignore next 1*/ text?.toString() ?? ''}
      </Markdown>
    </Typography>
  )
}

export default Hints
