import { SxProps } from '@mui/system'
import { ThemeObjectInterFace } from '../../../util/types'

export const modalOverlay = (
  open: boolean,
  isLandScapeMode: boolean,
): SxProps => ({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: isLandScapeMode ? null : 'rgba(0, 0, 0, 0.5)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  opacity: open ? 1 : 0,
  zIndex: 100,
  transition: 'opacity 0.3s ease-in-out',
  pointerEvents: open ? 'auto' : 'none',
})

export const modalContent = (isLandScapeMode: boolean): SxProps => ({
  backgroundColor: '#fff',
  padding: '1rem',
  borderRadius: '20px',
  boxShadow: '4px 4px 0px rgba(0, 0, 0, 0.3)',
  ...(isLandScapeMode && {
    maxWidth: { xs: '50%', md: '70%' },
    minHeight: '40vh',
    padding: '20px 30px',
    border: '2px solid',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
  }),
})

export const modalOption: SxProps = {
  fontWeight: '500',
  textAlign: 'center',
  marginBottom: '10px',
  color: 'black',
}

export const buttonContainer = (isLandScapeMode: boolean): SxProps => ({
  display: 'flex',
  justifyContent: isLandScapeMode ? 'flex-end' : 'center',
  ...(isLandScapeMode && {
    position: 'absolute',
    bottom: {
      md: '30px',
      sm: '15px',
    },
    right: {
      md: '50px',
      sm: '15px',
    },
  }),
})

export const buttonStyle = (globalTheme: ThemeObjectInterFace): SxProps => ({
  fontSize: 20,
  borderRadius: 2,
  fontWeight: '400',
  textTransform: 'none',
  padding: '5px 30px',
  minWidth: 'unset',
  backgroundColor: 'white',
  lineHeight: 1,
  color: globalTheme?.main_color,
  boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
  border: `2px solid ${globalTheme?.main_color}`,
  '&:hover': {
    backgroundColor: 'white',
  },
})
