import { SxProps } from '@mui/system'

export const container: SxProps = {
  display: 'flex',
  justifySelf: 'end',
  flexFlow: 'row-reverse',
  paddingRight: 1,
}

export const wrapper = (isLandScapeMode: boolean): SxProps => ({
  position: 'absolute',
  top: isLandScapeMode ? '8%' : '4%',
  zIndex: '100',
  fontSize: 30,
  color: 'white',
})

export const imageWrapper: SxProps = {
  maxWidth: '100%',
  maxHeight: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  inset: 0,
}

export const imageStyle: SxProps = {
  borderRadius: '10px',
  zIndex: 10,
}
