import { SxProps } from '@mui/system'
import { ThemeObjectInterFace } from '../../../../../util/types'

/* istanbul ignore next */
export const sliderTitle = (globalTheme: ThemeObjectInterFace): SxProps => ({
  color: globalTheme?.main_color,
  textAlign: 'center',
  fontWeight: 600,
  marginBottom: 1,
})

export const loadingContainer: SxProps = {
  padding: '2rem 0',
  display: 'flex',
  justifyContent: 'center',
}

export const valueContainer: SxProps = {
  display: 'flex',
  justifyContent: 'space-between',
}

/* istanbul ignore next */
export const valueContent = (globalTheme: ThemeObjectInterFace): SxProps => ({
  color: globalTheme?.main_color,
  lineHeight: 1.3,
  textAlign: 'center',
  fontWeight: 300,
  width: 0,
})

/* istanbul ignore next */
export const sliderStyle = (
  globalTheme: ThemeObjectInterFace,
  isSlider: boolean,
): SxProps => ({
  color: globalTheme?.main_color,
  height: 2,
  padding: '15px 0',
  '& .MuiSlider-thumb': {
    height: 30,
    width: 30,
    marginLeft: '5px',
    display: isSlider ? 'none' : 'block',
  },

  '& .MuiSlider-track': {
    border: 'none',
    opacity: isSlider ? 0.5 : null,
    color: isSlider ? '#bfbfbf' : '',
  },
  '& .MuiSlider-markActive': isSlider
    ? {
        color: 'white',
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: 20,
        borderColor: globalTheme?.main_color,
      }
    : {},
  '& .MuiSlider-rail': {
    opacity: 0.5,
    backgroundColor: '#bfbfbf',
    color: 'red',
  },
  '& .MuiSlider-mark': {
    backgroundColor: 'white',
    height: '10px',
    width: '10px',
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 20,

    '&.MuiSlider-markActive': {
      opacity: 1,
      backgroundColor: 'currentColor',
    },
  },
})

export const rangeContainer: SxProps = {
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
}
