// GlobalContext.tsx
import React, {
  createContext,
  useState,
  useEffect,
  ReactNode,
  useContext,
  useMemo,
} from 'react'
import { GlobalContextProps, GlobalObject } from '../util/types'
import { ThemeObjectLandscape } from '../util/constants'

const GlobalContext = createContext<GlobalContextProps | undefined>(undefined)

const GlobalProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [globalObject, setGlobalObject] = useState<GlobalObject>({
    themeData: { theme: { ...ThemeObjectLandscape, orientation: '' } },
  })
  /* istanbul ignore next 2*/
  const updateGlobalObject = (newObject: GlobalObject) => {
    setGlobalObject(newObject)
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = new URL(window?.location?.href)

        const pathname = url.pathname
        const id = pathname.split('/')[1]
        /* istanbul ignore next */
        const api_protocol =
          process.env.REACT_APP_MX8_SURVEY_API_PROTOCOL ?? 'https'
        const api_url = `${api_protocol}://${process.env.REACT_APP_MX8_SURVEY_API_DOMAIN}/v1/theme/${id}`
        const response = await fetch(api_url, {
          method: 'GET',
        })
        /* istanbul ignore next */
        const themeData = await response.json()
        /* istanbul ignore next */
        if (response.ok) {
          localStorage.clear()
          updateGlobalObject({
            ...globalObject,
            themeData,
          })
        }
      } catch (error) {}
    }

    fetchData()
  }, [])
  const contextValue = useMemo(
    () => ({ globalObject, updateGlobalObject }),
    [globalObject, updateGlobalObject],
  )
  return (
    <GlobalContext.Provider value={contextValue}>
      {children}
    </GlobalContext.Provider>
  )
}

export { GlobalProvider, GlobalContext }

export const useThemeContext = () => {
  const context = useContext(GlobalContext)
  /* istanbul ignore next */
  if (!context) {
    throw new Error('useThemeContext must be used inside the ThemeProvider')
  }
  return context
}
