import { SxProps } from '@mui/system'

export const styleForLandscapeMainBox: SxProps = {
  height: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  gap: { md: null, xs: 1 },
  '& > *': {
    flexBasis: 'calc(50% - 20px)',
  },
}

export const optionsContainer = (isLandScapeMode: boolean): SxProps => ({
  margin: isLandScapeMode ? null : '0 15px 10px',
  display: 'flex',
  justifyContent: isLandScapeMode ? 'center' : 'flex-end',
  flexWrap: 'wrap',
})
export const multiSelectMainContainer = (
  isLandScapeMode: boolean,
): SxProps => ({
  marginBottom: isLandScapeMode ? 8 : null,
})
