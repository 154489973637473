// grid landscape style
import { SxProps } from '@mui/system'
import { ThemeObjectInterFace } from '../../../util/types'

export const gridMainContainer: SxProps = {
  padding: { md: '20px 25px', sm: '10px 15px' },
  margin: '0 auto',
  borderRadius: '20px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  marginBottom: { sm: '30px' },
}
export const gridMainBox = (
  globalTheme: ThemeObjectInterFace,
  isGrid_numeric: boolean,
  isColumns: string[] | undefined,
): SxProps => ({
  width:
    /* istanbul ignore next 1 */
    isGrid_numeric && !isColumns ? 'auto' : '100%',
  padding: '20px 25px',
  border: `2px solid ${globalTheme?.main_color}`,
  margin: '0 auto 20px',
  borderRadius: '20px',
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: 'white',
  boxShadow: 'rgba(0, 0, 0, 0.15) 5.95px 5.95px 2.6px',
})

export const valueContainer: SxProps = {
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
}

export const titleMainContainer: SxProps = {
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
  width: '100%',
  flexDirection: 'column',
}

export const rowContainer = (color: string, isSlider: boolean): SxProps => ({
  flex: '1 1 auto',
  display: 'flex',
  ...(!isSlider && {
    border: `1px solid ${color}`,
    borderBottom: 0,
    '&:last-child': {
      borderBottom: `1px solid ${color}`,
    },
  }),
})

export const rowratingPointText = (
  globalTheme: ThemeObjectInterFace,
  isImageExist: boolean,
): SxProps => {
  return {
    color: globalTheme?.main_color,
    fontWeight: '300',

    textAlign: /* istanbul ignore next */ isImageExist ? 'end' : 'center',
    fontSize: { md: '20px', xs: '12px' },
    padding: '10px 20px 10px 0px',
  }
}
export const gridTotalValueText = (
  globalTheme: ThemeObjectInterFace,
  isGrid_numeric: boolean,
  inputValidColor?: boolean,
): SxProps => {
  return {
    color:
      /* istanbul ignore next */
      isGrid_numeric && !inputValidColor ? '#FF7F7F' : globalTheme?.main_color,
    fontWeight: '300',
    textAlign: 'center',
    fontSize: '20px',
    padding: '10px 20px',
  }
}
export const columnsBottomTotoalValSemContainer = {
  display: 'flex',
  width: '100%',
  justifyContent: 'space-around',
}
export const rowsTotalValcontainer = {
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  marginLeft: 4,
}

export const columnsBottomTotalValContainer = (
  globalTheme: ThemeObjectInterFace,
): SxProps => {
  return {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    borderTop: `1px solid ${globalTheme?.main_color}`,
  }
}
export const columnsToplable = (globalTheme: ThemeObjectInterFace): SxProps => {
  return {
    textAlign: 'center',
    width: '100%',
    color: globalTheme?.main_color,
    fontWeight: '300',
    fontSize: '18px',
    wordBreak: 'break-all',
    paddingBottom: 0.5,
  }
}
export const bottomTotaltext = {
  height: 50,
  display: 'flex',
  alignItems: 'flex-start',
  width: '100%',
  justifyContent: 'flex-end',
  paddingRight: 2.7,
  paddingTop: 1,
}
export const totalText = (globalTheme: ThemeObjectInterFace): SxProps => {
  return {
    fontWeight: '300',
    fontSize: '20px',
    color: globalTheme?.main_color,
  }
}
export const textImageContainer: SxProps = {
  display: 'flex',
  minWidth: '20%',
  flexDirection: 'column',
}
/* istanbul ignore next 2 */
export const textContainer = (height: number): SxProps => ({
  height: height > 0 ? height : 'inherit',
  minHeight: { md: 100, xs: 40 },
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
})
export const CommonImageStyle = (height: number): SxProps => ({
  maxHeight: height,
  minHeight: { md: 80, xs: 40 },
  width: { md: '100%' },
  paddingRight: { md: 1, xs: 0.5 },
  paddingTop: { xs: 0.5 },
  marginRight: 0.5,
})

export const textWrapper: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  minWidth: '20%',
}

export const leftContainer = (isSliderType: boolean): SxProps => ({
  display: 'flex',
  flexDirection: 'column',
  flex: '1 1 auto',
  minWidth: isSliderType ? '60%' : null,
})

/* istanbul ignore next 2 */
export const sliderContainer = (height: number) => ({
  height: height > 0 ? height : 'inherit',
  minHeight: { md: 100, xs: 40 },
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

/* istanbul ignore next 8 */
export const gridValueContent = (
  globalTheme: ThemeObjectInterFace,
  isSelected: boolean,
  disabled: boolean | undefined,
  height: number,
  isGrid_numeric: boolean,
): SxProps => {
  return {
    height: height > 0 ? height : 'inherit',
    minHeight: { md: 100, xs: 40 },
    flex: '1 1 auto',
    ...(!isGrid_numeric && {
      padding: { md: '10px 20px', xs: '5px 4px' },
    }),

    borderRight: `1px solid ${globalTheme.main_color}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    color: globalTheme?.main_color,
    cursor: 'pointer',
    textAlign: 'center',
    '&:last-child': {
      borderRight: 0,
    },
    ...(disabled && {
      backgroundColor: '#ddd',
      color: 'gray',
    }),
    ...(isSelected && {
      backgroundColor: globalTheme?.main_color,
      color: 'white',
    }),
  }
}

export const gridButtonText = (isSelected: boolean): SxProps => ({
  fontSize: { md: '20px', xs: '12px' },
  fontWeight: '400',
  marginBottom: '0',
  ...(isSelected && {
    color: '#fff',
  }),
})
