import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import RotateIcon from '../../../assets/rotateIcon.png'
import { rotateContainer, rotateWrapper, textContent } from './styles'

/* istanbul ignore next */
const RotateMessage: React.FC = () => {
  return (
    <Box data-testid={'rotate-comp'} sx={rotateContainer}>
      <Box sx={rotateWrapper}>
        <Typography sx={textContent}>
          Please rotate your screen through 90 degress to do today's survey
        </Typography>
        <Box
          component="img"
          src={RotateIcon}
          alt="alternate-text"
          sx={{ width: 160 }}
        />
      </Box>
    </Box>
  )
}

export default RotateMessage
