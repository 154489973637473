import { SxProps } from '@mui/system'
import { ThemeObjectInterFace } from '../../../util/types'

export const textContent = (
  globalTheme: ThemeObjectInterFace,
  isSelf: boolean,
): SxProps => ({
  color: isSelf ? globalTheme?.secondary_color : null,
  whiteSpace: 'pre-line',
  lineHeight: 1.3,
  '& *:not(:last-child)': {
    marginBottom: '5px',
  },
})
