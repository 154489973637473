import { SxProps } from '@mui/system'
/* istanbul ignore next */
export const withTextImageStyle = (
  isLandScapeMode?: boolean,
  type?: string,
) => ({
  ...(isLandScapeMode
    ? { maxHeight: { md: '12vh', xs: '15vh' } }
    : { maxHeight: type == 'rankSorter' ? '5vh' : '9vh' }),

  maxWidth: isLandScapeMode ? '100%' : '100px',
})
/* istanbul ignore next */
export const onlyImageStyle = (
  sx?: SxProps,
  isLandScapeMode?: boolean,
  type?: string,
  imageLoad?: boolean,
) => {
  /* istanbul ignore next 6 */
  if (imageLoad) return
  if (sx) {
    return sx
  } else if (isLandScapeMode) {
    /* istanbul ignore next */
    return { maxHeight: { md: '12vh', xs: '15vh' }, maxWidth: '100%' }
  } else {
    return {
      maxHeight: type == 'rankSorter' ? '5vh' : '9vh',
      maxWidth: '100px',
    }
  }
}
export const loaderContainer: SxProps = {
  display: 'flex',
  justifyContent: 'center',
  width: { md: '100%', xs: '50%' },
  padding: { md: 2 },
  paddingRight: { xs: 0.1 },
}
export const imageTextWrapper = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
  cursor: 'pointer',
  borderRadius: 0,
}

export const textStyle = (isLandScapeMode?: boolean) => ({
  ...(isLandScapeMode
    ? {
        maxWidth: { md: '300px', xs: '150px' },
        WebkitLineClamp: 2,
      }
    : {
        maxWidth: '100px',
        fontSize: 14,
        WebkitLineClamp: 1,
      }),
  width: '100%',
  lineHeight: 1.2,
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
})
