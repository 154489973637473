import { SxProps } from '@mui/system'
import {
  ButtonCurrentValProps,
  ThemeObjectInterFace,
} from '../../../util/types'

export const actionBtn = (
  globalTheme: ThemeObjectInterFace,
  isSelected: boolean,
  currentVal: ButtonCurrentValProps,
): SxProps => {
  const defaultStyle: SxProps = {
    ...(!currentVal?.image && {
      padding: { md: '0 30px' },
    }),

    border: `2px solid ${globalTheme?.main_color}`,
    fontSize: { md: 18, xs: 12 },
    borderRadius: 2,
    fontWeight: '400',
    textTransform: 'none',
    margin: 0.5,
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
    '&:disabled': {
      borderColor: 'white',
      backgroundColor: 'gray',
      color: 'white',
      cusrsor: 'not-allowed',
      '&:hover': {
        backgroundColor: 'gray',
        color: 'white',
      },
    },
    '&:focus': {
      outline: `2px solid ${globalTheme?.main_color}`,
      outlineOffset: '2px',
    },
  }

  if (isSelected) {
    return {
      ...defaultStyle,
      borderColor: 'white',
      backgroundColor: `${globalTheme?.main_color}`,
      color: globalTheme?.secondary_color,
      '&:hover': {
        backgroundColor: globalTheme?.main_color,
        color: globalTheme?.secondary_color,
      },
    }
  } else {
    return {
      ...defaultStyle,
      backgroundColor: 'white',
      '&:hover': {
        backgroundColor: 'white',
        color: null,
      },
    }
  }
}
