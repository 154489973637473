import { SxProps } from '@mui/system'
import { ThemeObjectInterFace } from '../../../../../util/types'

/* istanbul ignore next */
export const textContent = (globalTheme: ThemeObjectInterFace): SxProps => ({
  color: globalTheme?.main_color,
  lineHeight: 1.3,
  textAlign: 'center',
  fontWeight: 300,
  marginBottom: 2,
})

export const loadingContainer: SxProps = {
  padding: '2rem 0',
  display: 'flex',
  justifyContent: 'center',
}

export const valueContainer: SxProps = {
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
}

/* istanbul ignore next */
export const valueContent = (
  globalTheme: ThemeObjectInterFace,
  isSelected: boolean,
): SxProps => ({
  border: 1,
  width: '100%',
  textAlign: 'center',
  borderColor: globalTheme?.main_color,
  backgroundColor: isSelected ? globalTheme?.main_color : 'white',
  color: isSelected ? 'white' : globalTheme?.main_color,
  cursor: 'pointer',
  fontSize: 20,
})

export const selectedValueContainer: SxProps = {
  display: 'flex',
  justifyContent: 'space-between',
  marginLeft: '-2px',
}
