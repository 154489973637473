import { SxProps } from '@mui/system'

export const buttonStyle = (
  color: string,
  selected: boolean,
  disbaled: boolean,
  isLast?: boolean,
  isFirst?: boolean,
): SxProps => ({
  maxWidth: '32%',
  width: '100%',
  padding: '5px 20px',
  border: `2px solid ${color}`,
  borderRadius: 0,
  color,
  fontSize: '17px',
  fontWeight: 400,
  textTransform: 'none',
  ...(!isLast && {
    borderBottom: 0,
  }),
  ...(disbaled && {
    borderColor: '#b3b3b3',
    backgroundColor: '#b3b3b3',
    color: '#fff',
    ...(!isFirst && {
      borderTopColor: '#fff',
    }),
    '&:hover': {
      backgroundColor: '#b3b3b3',
    },
  }),
  ...(selected && {
    borderColor: color,
    backgroundColor: color,
    color: '#fff',
    ...(!isFirst && {
      borderTopColor: '#fff',
    }),
    '&:hover': {
      backgroundColor: color,
    },
  }),
})

export const textStyle = (selected: boolean, disabled: boolean): SxProps => ({
  fontWeight: 400,
  marginBottom: 0,
  fontSize: '17px',
  lineHeight: 1.75,
  ...(selected && {
    color: '#fff',
  }),
  ...(disabled && {
    color: '#fff',
  }),
})
