import { SxProps } from '@mui/system'
import { modalOverlay } from '../../../Modal/styles'

export const modalParent = (
  isModalOpen: boolean,
  isLandScapeMode: boolean,
): SxProps => ({
  ...modalOverlay(isModalOpen, isLandScapeMode),
  ...(isModalOpen ? { visibility: 'visible' } : { visibility: 'hidden' }),
  backgroundColor: 'rgba(0, 0, 0, 0.3)',
})
export const modalContainer = (color: string): SxProps => ({
  width: { md: '40%', sm: '50%', xs: '80%' },
  padding: '20px 30px',
  borderRadius: '12px',
  backgroundColor: '#000',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '20px',
  color,
})

export const inputContainer = {
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  gap: '20px',
}
