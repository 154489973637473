import { SxProps } from '@mui/system'

export const chipWrapper = (isLandScapeMode: boolean): SxProps => ({
  display: 'flex',
  justifyContent: isLandScapeMode ? 'center' : 'flex-end',
  alignItems: 'center',
  flexWrap: 'wrap',
})

export const optionContainer = (isLandScapeMode: boolean): SxProps => ({
  margin: isLandScapeMode ? null : '0 15px 10px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: isLandScapeMode ? 'center' : 'flex-end',
  justifyContent: 'center',
})

export const styleForLandscapeMainBox: SxProps = {
  display: 'flex',
  justifyContent: 'space-between',
  height: '100%',
  '& > *': {
    flexBasis: 'calc(50% - 20px)',
  },
}
