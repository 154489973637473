import { FC } from 'react'
import Draggable from 'react-draggable'
import { btnContainer, btnStyle, draggableContainer } from './style'
import { Box } from '@mui/system'
import { Button } from '@mui/material'
import { Comment, History } from '@mui/icons-material'
import { useThemeContext } from '../../../../../globalContext/global-context'
import CalculateIcon from '@mui/icons-material/Calculate'
import { UndoDragFloatingProps } from '../../../../../util/types'

const UndoDragFloating: FC<UndoDragFloatingProps> = ({
  isLandScapeMode,
  undoFunction,
  setIsCommentModalOpen,
  isEmptyVariableCheck,
  setIshowVariable,
  isShowVariable,
  floatinBtnRef,
}) => {
  const globalTheme = useThemeContext().globalObject.themeData.theme
  return (
    <Draggable
      axis="both"
      handle=".handle"
      defaultPosition={{
        x: isLandScapeMode
          ? Math.floor(innerWidth / 2 - 100)
          : Math.floor(innerWidth / 4 - 200),
        y: isLandScapeMode
          ? Math.floor(innerHeight / 2 - 200)
          : Math.floor(innerHeight / 2 - 400),
      }}
      scale={1}
      cancel="span, svg"
    >
      <Box sx={draggableContainer} className="handle" ref={floatinBtnRef}>
        <Box sx={btnContainer}>
          <Button
            onClick={undoFunction}
            sx={btnStyle(globalTheme.secondary_color)}
            className="no-cursor"
          >
            <History /> <span>Undo</span>
          </Button>
        </Box>
        <Box sx={btnContainer}>
          <Button
            data-testid="open-comment-modal"
            className="no-cursor"
            onClick={() => setIsCommentModalOpen(true)}
            sx={btnStyle(globalTheme.secondary_color)}
          >
            <Comment /> <span>Comment</span>
          </Button>
        </Box>
        {isEmptyVariableCheck && (
          <Box sx={btnContainer}>
            <Button
              data-testid="open-variable-store-floating"
              className="no-cursor"
              onClick={() => setIshowVariable(!isShowVariable)}
              sx={btnStyle(globalTheme.secondary_color)}
            >
              <CalculateIcon sx={{ color: 'white' }} className="no-cursor" />
              <span>{isShowVariable ? 'Hide' : 'Show'}</span>
            </Button>
          </Box>
        )}
      </Box>
    </Draggable>
  )
}

export default UndoDragFloating
