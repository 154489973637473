import { Slider } from '@mui/material'
import { Box } from '@mui/system'
import React, { FC, SyntheticEvent } from 'react'
import { sliderStyle } from '../../..//Rating/components/SliderRating/styles'
import { MultiSliderRatingProps } from '../../../../../util/types'
import { useThemeContext } from '../../../../../globalContext/global-context'

const MultiSliderComp: FC<MultiSliderRatingProps> = ({
  interval,
  UpdateMarkValue,
  sliderValue,
  setSliderValue,
  parentIndex,
  previous_response,
}): React.ReactElement => {
  const { globalObject } = useThemeContext()
  const globalTheme = globalObject?.themeData?.theme

  const handleCommitChange = (
    event: SyntheticEvent | Event,
    value: number | number[],
  ) => {
    if (typeof value === 'number') {
      setSliderValue((prevValues) => ({
        ...prevValues,
        [parentIndex]: {
          sliderRatingValue: value,
        },
      }))
    }
  }

  const isSlider = sliderValue[parentIndex]?.sliderRatingValue === undefined

  return (
    <Box
      sx={{
        width: '100%',
        paddingTop: '10px',
      }}
    >
      <Slider
        data-testid={`slider-${parentIndex}`}
        onChangeCommitted={handleCommitChange}
        aria-label="Custom marks"
        defaultValue={
          previous_response ? (+previous_response - 1) * interval() : undefined
        }
        step={interval()}
        value={sliderValue[parentIndex]?.sliderRatingValue}
        marks={UpdateMarkValue}
        sx={sliderStyle(globalTheme, isSlider)}
      />
    </Box>
  )
}

export default MultiSliderComp
