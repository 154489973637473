import { CSSProperties } from 'react'
import { videoIframe } from '../VideoResponse/styles'

export const dialContainer = (mobileView: boolean, dialOpen: boolean) =>
  dialOpen && {
    position: 'fixed',
    inset: 0,
    overflow: 'auto',
    backgroundColor: 'black',
    paddingX: mobileView ? 1 : 4,
    paddingTop: mobileView ? '1.5%' : '1%',
  }

export const dialWraper = (dialOpen: boolean) =>
  dialOpen && {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  }

export const mobileViewDialStyleTextStyle = (dialStyleRight: boolean) => {
  return {
    color: '#49AE8A',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitLineClamp: 2,
    textAlign: dialStyleRight ? 'end' : 'start',
  }
}
export const subContainerForSliderBottom = () => {
  return {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row-reverse',
  }
}
export const mainBoax = (
  mobileView: boolean,
  dialOpen: boolean,
  dialStyleRight: boolean,
) =>
  dialOpen && {
    position: 'relative',
    display: 'flex',
    flexDirection: mobileView || dialStyleRight ? 'row' : 'column',
    width: '100%',
    height: '40%',
  }

export const videoWrapper = (
  mobileView: boolean,
  dialOpen: boolean,
  dialStyleRight: boolean,
) =>
  dialOpen && {
    ...(dialStyleRight ? { width: '90%' } : { width: '100%' }),
    ...(mobileView && { width: '70%' }),
    position: 'relative',
    paddingBottom: mobileView || dialStyleRight ? '42%' : '35%',
    inset: 0,
  }

export const hintsStyle = (mobileView: boolean) =>
  mobileView
    ? {
        color: '#49AE8A',
        fontSize: 14,
        textAlign: 'center',
        paddingX: 2,
        marginTop: 2,
      }
    : { color: '#49AE8A', fontSize: 18 }

export const iframeStyle = (
  dialOpen: boolean,
  isLandScapeMode: boolean,
  dialStyleRight: boolean,
  mobileView: boolean,
): CSSProperties =>
  /* istanbul ignore next */
  dialOpen
    ? {
        position: 'absolute',
        inset: 0,
        width: '100%',
        height: '100%',
        border: 'none',
        ...(!mobileView && dialStyleRight && { top: 80 }),
      }
    : videoIframe(!!isLandScapeMode)

export const mobileViewWrapper = (
  mobileView: boolean,
  dialStyleRight: boolean,
) => ({
  ...(!mobileView && dialStyleRight && { marginTop: 4 }),
  width: mobileView || dialStyleRight ? '30%' : '100%',
  overflow: mobileView ? 'auto' : 'visible',
})

export const sliderWrapper = (
  mobileView: boolean,
  dialStyleRight: boolean,
) => ({
  display: 'flex',
  alignItems: 'center',
  ...(mobileView || dialStyleRight
    ? {
        flexDirection: 'row',
        height: '100%',
      }
    : { flexDirection: 'column', height: '80%' }),
  ...(!mobileView && dialStyleRight && { marginRight: 7 }),
})

export const dialSliderStyle = (
  mobileView: boolean,
  lastIndexOfSliderLabel: number,
  dialStyleRight: boolean,
) => ({
  ...(dialStyleRight && { marginLeft: 0.5 }),
  boxShadow: 'none',
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: 12,
    background: 'unset',
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: '50% 50% 50% 0',
    backgroundColor: '#44A280',
    color: '#44A280',
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&::before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
    },
    '& > *': {
      transform: 'rotate(45deg)',
    },
  },
  '& .MuiSlider-markLabel':
    mobileView || dialStyleRight
      ? {
          visibility: 'hidden',
          position: 'absolute',
          left: '-255%',
          color: '#44A280',
          textAlign: 'center',
          width: '90px',
          wordBreak: 'break-all',
          overflowWrap: 'break-word',
          whiteSpace: 'normal',
          transform: 'translateY(17%)',
        }
      : {
          width:
            /* istanbul ignore next */ lastIndexOfSliderLabel > 5
              ? '100px'
              : '200px',
          justifyContent: 'center',
          whiteSpace: 'normal',
          color: '#49AE8A',
          textAlign: 'center',
          ' &[data-index="0"]': {
            visibility: 'hidden',
          },
          [`&[data-index="${lastIndexOfSliderLabel}"]`]: {
            visibility: 'hidden',
          },
        },

  '& .MuiSlider-mark': {
    backgroundColor: '#49AE8A',
    height: '10px',
    width: '10px',
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 20,
    opacity: 1,
  },
  '& .MuiSlider-thumb': {
    background: 'none',
    boxSizing: 'border-box',
    boxShadow: 'none',
    paddingLeft: 0.8,
    ...((mobileView || dialStyleRight) && {
      transform: 'translateX(250%)',
      rotate: '90deg',
    }),

    border: 'none',
  },

  '.MuiSlider-thumb.Mui-focusVisible, .MuiSlider-thumb:hover ': {
    boxShadow: 'none !important',
  },
})
