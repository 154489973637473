import { SxProps } from '@mui/system'

export const draggableContainer: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  position: 'fixed',
  backgroundColor: '#000',
  borderRadius: '12px',
  zIndex: 90,
  cursor: 'grab',
}

export const vairableStoreMainContainer: SxProps = {
  borderRadius: '12px',
  backgroundColor: '#000',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'start',
  padding: '15px 15px 15px 15px',
  width: '100%',
  minWidth: { md: '20rem', xs: '12rem' },
}

export const titleBoxConatiner: SxProps = {
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  gap: 1,
}

export const varibleStoreId: SxProps = {
  color: 'white',
  fontSize: { xs: 12, md: 14 },
  textTransform: 'uppercase',
}
