import { SxProps } from '@mui/system'
import { ThemeObjectInterFace } from '../../../util/types'

export const headerContainer = (
  globalTheme: ThemeObjectInterFace,
  timerImageOPen: boolean,
): SxProps => ({
  width: 'inherit',
  padding: '10px 20px 10px 20px;',
  borderRadius: '20px 20px 0px 0px',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  backgroundColor: `${globalTheme?.main_color}`,
  boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.3)',
  opacity: timerImageOPen ? '0.1' : '1',
})

export const headerInfo = (globalTheme: ThemeObjectInterFace): SxProps => ({
  fontSize: 13,
  color: globalTheme?.secondary_color,
  fontWeight: '400',
})

export const headerName = (globalTheme: ThemeObjectInterFace): SxProps => ({
  fontSize: 18,
  color: globalTheme?.secondary_color,
  fontWeight: '900',
})
