import { Box, SxProps } from '@mui/system'
import { FC, useState } from 'react'
import { ButtonCurrentValProps } from '../../../util/types'
import {
  imageTextWrapper,
  loaderContainer,
  onlyImageStyle,
  textStyle,
  withTextImageStyle,
} from './styles'
import { CircularProgress } from '@mui/material'

type CommonImageCompProps = {
  currentVal: ButtonCurrentValProps
  type?: string
  isLandScapeMode?: boolean
  sx?: SxProps
}

const CommonImageComp: FC<CommonImageCompProps> = ({
  currentVal,
  type,
  isLandScapeMode,
  sx,
}) => {
  const [imageLoad, setImageLoad] = useState(true)
  const { image, text } = currentVal
  /* istanbul ignore next  */
  if (image && text) {
    return (
      <Box sx={imageTextWrapper}>
        {/* button text */}
        <Box sx={textStyle(isLandScapeMode)}>{text}</Box>
        {/* button image */}
        <Box
          component="img"
          className="buttons-image-reponsive"
          data-testid="common-image&text"
          src={image}
          sx={withTextImageStyle(isLandScapeMode, type)}
          onLoad={() => {
            setImageLoad(false)
          }}
        />
        {imageLoad && (
          <Box className="buttons-image-reponsive" sx={loaderContainer}>
            <CircularProgress size="1.5rem" />
          </Box>
        )}
      </Box>
    )
  } else if (image) {
    return (
      <>
        <Box
          component="img"
          className={'buttons-image-reponsive'}
          data-testid="common-image"
          src={image}
          sx={onlyImageStyle(sx, isLandScapeMode, type, imageLoad)}
          onLoad={() => {
            setImageLoad(false)
          }}
        />

        {imageLoad && (
          <Box className="buttons-image-reponsive" sx={loaderContainer}>
            <CircularProgress size="1.5rem" />
          </Box>
        )}
      </>
    )
  } else {
    return <Box data-testid="common-text">{text}</Box>
  }
}

export default CommonImageComp
