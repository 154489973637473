import { TextField } from '@mui/material'
import React from 'react'
interface MultiInputsComponentsProps {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  colIndex: number
  rowIndex: number
  defaultValue?: string
}

const MultiInputsComponents = ({
  onChange,
  colIndex,
  rowIndex,
  defaultValue,
}: MultiInputsComponentsProps) => {
  return (
    <TextField
      data-testid={`Numeric-Grid-${rowIndex}${colIndex}`}
      onChange={onChange}
      defaultValue={defaultValue}
      fullWidth
      sx={{
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            border: 'none',
          },
        },
        '& .MuiInputBase-input': {
          textAlign: 'center',
          fontSize: '20px',
          color: '#49AE8A',
        },
      }}
      autoFocus={colIndex == 0 && rowIndex == 0}
      type="number"
      InputProps={{
        type: 'number',
        sx: {
          '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
            {
              display: 'none',
            },
          '& input[type=number]': {
            MozAppearance: 'textfield',
          },
        },
      }}
    />
  )
}

export default MultiInputsComponents
