import { SxProps } from '@mui/system'
import { ThemeObjectInterFace } from '../../util/types'

export const commonContainer = (isSelf: boolean): SxProps => ({
  paddingLeft: isSelf ? '20%' : '20px',
  paddingRight: isSelf ? '20px' : '20%',
  display: 'flex',
  margin: '8px 0',
  justifyContent: isSelf ? 'flex-end' : 'flex-start',
})

export const commonWrapper = (
  globalTheme: ThemeObjectInterFace,
  isSelf: boolean,
  timerImageOPen: boolean,
  isLandScapeMode?: boolean,
): SxProps => ({
  py: 1,
  px: 2,
  position: 'relative',
  boxShadow: '4px 4px 1px rgba(0, 0, 0, 0.3)',
  opacity: timerImageOPen ? '0.1' : '1',
  backgroundColor: `${isSelf ? globalTheme?.main_color : 'background.paper'}`,
  borderRadius: `${isSelf ? '10px 0px 10px 10px' : '0px 10px 10px 10px'}`,
  color: `${isSelf ? 'primary.contrastText' : 'text.primary'}`,

  '&::after':
    /* istanbul ignore next */
    !isLandScapeMode
      ? {
          content: '""',
          width: 0,
          height: 0,
          borderStyle: 'solid',
          borderWidth: isSelf ? '7px' : '8px',
          borderColor: `${globalTheme?.main_color} transparent transparent ${globalTheme?.main_color}`,
          position: 'absolute',
          top: '0',
          right: isSelf ? '-13px' : null,
          left: isSelf ? null : '-15px',
          filter: isSelf
            ? 'drop-shadow(5px 2px 0.5px rgba(0,0,0,.3))'
            : 'drop-shadow(-2px 1px 1px rgba(0,0,0,.3))',
        }
      : {},
})
